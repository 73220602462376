@import url('https://fonts.googleapis.com/css2?family=Mallanna&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,800;1,100;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;0,900;1,400;1,700&display=swap');
@import './standard.css';

* {
  /* font-family: 'Mallanna', sans-serif; */
  font-family: 'Poppins', sans-serif;
}

#root {
  background-color: #f1f1f1;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  margin-top: 70px;
  background-color: #f1f1f1;
}

.full-width {
  width: 100%;
}

.margin-top-10 {
  margin-top: 10px;
}

.flex-column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-column-end-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
}

.flex-row-around-start {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
}

.flex-row-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav1-background {
  background-color: #202020;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wrapper {
  width: min(1000px, 100%);
}

.nav1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav1 h1 {
  color: #f3f3f3;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.logo {
  height: 70px;
}

.logo a img {
  height: 50px;
  margin: 5px 0px 5px 0px;
}

.right-nav1 {
  height: 70px;
  display: flex;
  padding: 0px 10px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.right-nav1 ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 70px;
  margin: 0px;
  padding: 0px 40px 0px 0px;
}

.right-nav1 ul li {
  color: #fff;
  line-height: 70px;
  margin: 0;
  padding: 0px 30px;
}

.right-nav1 ul li a {
  text-decoration: none;
  line-height: 50px;
  display: block;
  color: #f3f3f3;
  font-size: 15px;
}

.right-nav1 ul li a:hover {
  color: #f3f3f3;
  text-shadow: 0px 1px 1px #f3f3f3;
  cursor: pointer;
}

.right-nav1 button {
  height: 40px;
  width: 110px;
  background-color: #fefefe;
  border-radius: 10px;
  border: 2px solid #aaa;
  font-size: 16px;
}

.right-nav1 button:hover {
  border: 2px solid #fff;
  box-shadow: 2px 2px 2px #aaa;
  text-shadow: 1px 0px 0px #ccc;
  cursor: pointer;
}


.landing-wrapper {
  width: min(100%, 1050px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.landing-page {
  height: calc(100vh - 15px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-image: url('../public/img/cars-charging.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.charging-operator {
  width: 50%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 100px;
}

.charging-operator h1 {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 900;
  font-size: 70px;
  line-height: 70px;
  color: #fff;
  font-weight: 400;
  text-shadow: 3px 3px 3px #303030;
}

.landing-image {
  width: 50%;
  height: 500px;
  padding-bottom: 140px;
}

.landing-image img {
  height: 430px;
}

.home-icons {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 50px 0px 50px 0px;
}

.icon {
  background-color: #e5e5e5;
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: 1s;
}

.icon img {
  padding: 10px;
  height: 60px;
}

.icon p {
  font-size: 13px;
  padding: 5px 10px 10px 10px;
  text-align: center;
  color: #444;
}

.footer {
  background-color: #202020;
  width: 100%;
  padding: 75px 0px 25px 0px;
  margin-top: auto;
}

.footer p {
  font-weight: 400;
  color: #c3c3c3;
  font-style: italic;
  font-size: 14px;
  line-height: 25px;
  font-family: 'Raleway', sans-serif;
  padding-top: 50px;
}

.footer-left {
  width: 33%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-right {
  width: 33%;
}

.footer-left ul, .footer-right ul {
  list-style: none;
}

.footer-left ul li, .footer-right ul li {
  text-align: left;
  color: #f3f3f3;
}

.footer-left ul li a, .footer-right ul li a {
  text-align: left;
  color: #f3f3f3;
  text-decoration: none;
  line-height: 30px;
}

.footer-center {
  width: 33%;
}

.footer-center img {
  width: 90px;
}


/* Login page */
.signin-page {
  height: max(calc(100vh - 70px), 700px);
  background-color: #f3f3f3;
  width: 100%;
}

.first-div {
  height: 700px;
  width: 600px;
  border-radius: 20px;
  background-color: #aaa;

  animation-duration: 1.1s;
  animation-name: changecolor1;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changecolor1 {
  from {
    background-color: #aaa;
  }

  to {
    background-color: #ccc;
  }
}

.second-div {
  height: 600px;
  width: 500px;
  border-radius: 17px;
  background-color: #555;

  animation-duration: 1.6s;
  animation-name: changecolor2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changecolor2 {
  from {
    background-color: #555;
  }

  to {
    background-color: #888;
  }
}

.third-div {
  height: 500px;
  width: 400px;
  border-radius: 17px;
  background-color: #202020;
  padding: 0px 0px 40px 0px;
}

.third-div h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 35px;
  color: #f3f3f3;
}

.third-div form {
  padding: 70px 40px 80px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
}

.third-div form input {
  margin: 20px 0px 0px 0px;
  width: 250px;
  font-size: 16px;
  line-height: 25px;
  border: 3px solid #ccc;
  border-radius: 5px;
  padding: 3px;
}

.third-div form input::placeholder {
  color: #777;
}

.third-div form input:focus::placeholder {
  color: #888;
}

.third-div form input:focus {
  border: 3px solid #999;
  outline: none;
}

.third-div form button {
  margin: 40px 0px 0px 0px;
  line-height: 30px;
  border-radius: 5px;
  border: 2px solid #777;
  color: #202020;
  font-size: 16px;
  font-weight: 400;
  background-color: #f3f3f3;
}

.third-div form button:hover {
  cursor: pointer;
  font-weight: 500;
  background-color: #e3e3e3;
}

.need-acount {
  color: #f3f3f3;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
}

.need-acount a {
  color: #f3f3f3;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
}

.errmsg {
  font-family: 'Noto Sans', sans-serif;
  color: #202020;
  display: block;
  background-color: #dd6b6b;
  border: 1px solid #ff0000;
  padding: 0px 10px;
  margin: 0px 0px 10px 0px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
}
.no-errmsg {
  visibility: hidden;
  font-family: 'Noto Sans', sans-serif;
  color: #202020;
  display: block;
  background-color: #dd6b6b;
  border: 1px solid #ff0000;
  margin: 0px 0px 10px 0px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
}



/* Register page */
.signup-page {
  height: max(calc(100vh - 70px), 700px);
  background-color: #f3f3f3;
  width: 100%;
}

.first-div-signup {
  height: 700px;
  width: 600px;
  border-radius: 20px;
  background-color: #aaa;

  animation-duration: 1.1s;
  animation-name: changecolor1;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changecolor1 {
  from {
    background-color: #aaa;
  }

  to {
    background-color: #ccc;
  }
}

.second-div-signup {
  height: 630px;
  width: 500px;
  border-radius: 17px;
  background-color: #555;

  animation-duration: 1.6s;
  animation-name: changecolor2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changecolor2 {
  from {
    background-color: #555;
  }

  to {
    background-color: #888;
  }
}

.third-div-signup {
  height: 540px;
  width: 400px;
  border-radius: 17px;
  background-color: #202020;
  padding: 0px 0px 30px 0px;
}

.third-div-signup h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 35px;
  text-align: center;
  color: #f3f3f3;
}

.third-div-signup form {
  padding: 90px 40px 80px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.third-div-signup form div input {
  width: 250px;
  border: none;
  font-size: 16px;
  line-height: 25px;
  padding: 3px;
  outline: none;
}

.third-div-signup form p {
  z-index: 100;
  display: block;
  position: relative;
}
.third-div-signup form div input::placeholder {
  color: #777;
}

.third-div-signup form div input:focus::placeholder {
  color: #888;
}

.third-div-signup form div input:focus {
  outline: none;
  border: none;
}

.third-div-signup form button {
  margin: 20px 0px 0px 0px;
  line-height: 30px;
  border-radius: 5px;
  border: 2px solid #777;
  color: #202020;
  font-size: 16px;
  font-weight: 400;
  background-color: #f3f3f3;
  width: 270px;
}

.third-div-signup form button:hover {
  cursor: pointer;
  font-weight: 500;
  background-color: #e3e3e3;
}

.third-div-signup form button:disabled,
.third-div-signup form button[disabled]{
  border: 1px solid #777;
  background-color: #ddd;
  color: #777;
}
.third-div-signup form button:disabled:hover,
.third-div-signup form button[disabled]:hover{
  border: 1px solid #999;
  color: #777;
  cursor: not-allowed;
  font-weight: 400;
  background-color: #ccc;
}

.offscreen {
  visibility: hidden;
}

.input-validation {
  display: flex;
  margin: 3px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 3px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  width: 270px;
}

.input-validation div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-right: 5px;
}

.valid {
  color: #78d98b;
}

.invalid {
  color: #dd6b6b;
}

.hide {
  display: none;
}

#uidnote, #pwdnote, #confirmnote {
  color: #333;
  font-size: 12px;
  padding: 5px;
  margin: 2px 0px;
  background-color: #bbb;
  display: block;
  width: 265px;
  border: 2px dotted #f3f3f3;
  border-radius: 3px;
  z-index: 2;
  text-align: left;
  line-height: 15px;
}


.info-icon {
  padding: 0px 3px 0px 0px;
}

.input-note {
  height: 60px;
}

.already-registered {
  color: #f3f3f3;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
}

.already-registered a {
  color: #f3f3f3;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
}

.signup-success {
  width: 100%;
}

.signup-success h1 {
  text-align: center;
  color: #f3f3f3;
  line-height: 50px;
  font-size: 30px;
}

.signup-success p a {
  text-align: center;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 30px;
}


/* Navbar2 */

.three-lines {
  width: 40px;
  height: 40px;
  background-image: url('../public/icons/three-lines.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  animation-duration: 0.3s;
  animation-name: smoothopacity1;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

.three-lines-notification {
  background-image: url('../public/icons/three-lines-notification.png') !important;
}

@keyframes smoothopacity1 {
  from { opacity: 0; }
  to { opacity: 1; }
}

.three-lines:hover {
  cursor: pointer;
}

.three-lines-x {
  width: 40px;
  height: 40px;
  background-image: url('../public/icons/x-icon.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center right;

  animation-duration: 0.5s;
  animation-name: smoothopacity2;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

@keyframes smoothopacity2 {
  from { opacity: 0; }
  to { opacity: 1; }
}

.three-lines-x:hover {
  cursor: pointer;
}

.no-display {
  display: none;
  opacity: 0;
  height: 0px;
  width: 0px;
}

.three-lines-menu {
  display: block !important;
  opacity: 1 !important;
  width: 90px;
  background-color: #e3e3e3;
  position: relative;
  top: 40px;
  right: 45px;
  z-index: 2;
  border: 1px solid #777;
  border-radius: 4px;
  transition: opacity 1s ease-out;
}

.three-lines-menu ul {
  display: block;
}

.three-lines-menu ul li {
  display: block;
}

.three-lines-menu ul li a {
  display: block;
  text-align: right;
  line-height: 23px;
  font-size: 16px;
  text-decoration: none;
  color: #202020;
  padding: 4px;
}

.three-lines-menu ul li a:hover {
  text-shadow: 1px 1px 1px #fff;
  background-color: #d3d3d3;
}

/* Dashboard */

.dashboard {
  width: 100%;
  background-color: #f1f1f1;
  min-height: calc(100vh - 300px);
  margin-bottom: 10px;
}

.stations-list {
  background-color: #202020;
  width: 1000px;
  min-height: calc(100vh - 330px);
  border: 4px solid #999;
  border-radius: 20px;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  flex-wrap: wrap;
}

.view-statistics {
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  color: #202020;
  padding: 30px 0px 5px 0px;
  font-size: 18px;
}

.view-statistics a {
  font-weight: 600;
  font-style: italic;
  color: #202020;
}

.station-preview {
  text-decoration: none;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  background-color: #d5d5d5;
  border: 2px solid #bbb;
  border-radius: 5px;
  width: 216px;
  height: 85px;
}

.station-preview:hover {
  background-color: #ccc;
}

.station-preview h2 {
  font-weight: 600;
  text-decoration: none;
  color: #202020;
  padding-bottom: 5px;
  font-size: 18px;
}

.address, .availability {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #202020;
  font-size: 14px;
}

.address {
  font-style: italic;
  padding-bottom: 10px;
}

.availability {
  line-height: 20px;
  padding-top: 18px;
}

.availability span {
  font-weight: 500;
}

.add-station {
  text-decoration: none;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  background-color: #d5d5d5;
  border: 2px solid #bbb;
  border-radius: 5px;
  width: 216px;
  height: 85px;
  color: #555;
}

.add-station:hover {
  background-color: #ccc;
  color: #303030;
}

.add-station h2 {
  font-size: 65px;
  font-weight: 400;
  line-height: 50px;
}

.add-station p {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

/* Navbar3 */
.back-to-dashboard {
  height: 70px;
  text-decoration: none;
}

.back-to-dashboard img {
  height: 18px;
  margin-right: 10px;
}

.back-to-dashboard p {
  height: 20px;
  font-size: 15px;
  margin-right: 10px;
  text-decoration: none;
  color: #f3f3f3;
  line-height: 20px;
  width: 85px;
}

/* Map */
.gm-style-iw-d h2 {
  font-size: 15px;
  line-height: 25px;
  color: #202020;
  font-weight: 600;
}

.gm-style-iw-d p {
  font-size: 13px;
  line-height: 20px;
  color: #555;
  font-weight: 400;
}

.gm-style-iw-c button {
  margin: 3px !important;
}

/* Add station page */
.add-station-page {
  width: 100%;
}

.app-page-title {
  line-height: 85px;
  font-size: 30px;
  font-weight: 600;
  padding: 10px;
  font-family: 'Raleway', sans-serif;
  color: #202020;
}

.add-station-page-div {
  width: 700px;
}

.add-station-page-div h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #202020;
}

.add-station-page-div p {
  margin-top: 20px;
  font-style: italic;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #202020;
}

.add-station-page-div p a {
  color: #444;

}

/* .add-station-page-div input {
  width: 400px;
  border-radius: 5px;
  line-height: 30px;
  font-size: 18px;
  border: 1px solid #aaa;
  margin: 10px 0px 40px 0px;
  padding: 3px 7px;
  color: #202020;
}

.add-station-page-div input::placeholder {
  color: #aaa;
}

.add-station-page-div input:focus {
  outline: none;
  border: 1px solid #202020;
} */

.map-div {
  background-color: #404040;
  padding: 5px;
  border-radius: 5px;
}

.map-div2 {
  width: 350px;
  background-color: #404040;
  padding: 5px;
  border-radius: 5px;
  display: block;
  margin: 10px auto;
}

.gm-style-iw-c {
  margin-bottom: 50px !important;;
}

.add-station-page-div button {
  margin: 40px 0px 0px 0px;
  width: 350px;
  background-color: #202020;
  color: #f3f3f3;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  line-height: 40px;
  border: 2px solid #404040;
}

.add-station-page-div button:hover {
  cursor: pointer;
  background-color: #101010;
  border: 2px solid #202020;
}

.add-station-page-div button:disabled {
  background-color: #555;
}

.add-station-page-div button:disabled:hover {
  cursor: not-allowed;
}

/* Page 404 */
.page-404 {
  height: calc(80vh);
}

.page-404 h2 {
  font-size: 25px;
  line-height: 40px;
  font-weight: 600;
  color: #202020;
  text-align: center;
}

.page-404 h3 {
  padding-top: 30px;
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  color: #202020;
  text-align: center;
}

.page-404 h3 a {
  color: #202020;
}

.logout-div {
  background-color: #e1e1e1;
  margin-bottom: 30px;
  border-radius: 20px;
  width: 400px;
  height: 200px;
}

.logout-div p, .logout-div a, .logout-div p a {
  color: #202020;
  font-size: 17px;
  line-height: 50px;
}


/* New Station page */

.new-station-page {
  width: 100%;
}

.new-station-step-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.step-1-map-column {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.step-1-map-column h2, .step1-column h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #202020;
  margin-top: 20px;
}

.step1-column h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-style: italic;
  color: #202020;
  margin-top: 40px;
}

.step-1-map-column h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #202020;
  padding-bottom: 20px;
}

.step-1-map-column p,  .step1-column-p {
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  color: #202020;
  padding-top: 10px;
}

.step1-column {
  width: 420px;
}

.my-classic-input {
  width: 350px;
  border-radius: 5px;
  line-height: 30px;
  font-size: 18px;
  border: 1px solid #aaa;
  margin: 10px 0px 10px 0px;
  padding: 3px 7px;
  color: #202020;
}

.my-classic-input::placeholder {
  color: #aaa;
}

.my-classic-input:focus {
  outline: none;
  border: 1px solid #202020;
}

.next-step {
  margin: 20px 0px 15px 0px;
  width: 400px;
  height: 50px;
  outline: none;
  border: 1px solid #000;
  border-radius: 2px;
  background-color: #202020;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 50px;
  transition: 0.3s;
}

.next-step:hover {
  background-color: #303030;
  border: 1px solid #404040;
  color: #fff;
  cursor: pointer;
}

.next-step img {
  height: 30px;
  padding-left: 20px;
}

.error-p {
  color: #ff0000 !important;
  font-style: normal !important;
}

.step-img {
  width: 500px;
}

.new-station-page div h1 {
  line-height: 40px;
  font-size: 30px;
  font-weight: 600;
  padding: 30px 0px 10px 0px;
  font-family: 'Raleway', sans-serif;
  color: #202020;
}

.new-station-page div h1 span {
  line-height: 40px;
  font-size: 30px;
  font-weight: 600;
  padding: 30px 0px 10px 0px;
  font-family: 'Noto Sans', sans-serif;
  color: #202020;
}


/* Autocomplete */

.my-input-div {
  display: flex;
  flex-direction: column !important;
  align-items: start;
  text-align: start;
  width: 100%;
}

.no-suggestions {
  color: #999;
  padding: 0.4rem;
  font-size: 0.9rem;
}

.suggestions {
  width: 350px;
  background-color: #e3e3e3;
  position: relative;
  top: -9px;
  right: -5px;
  z-index: 2;
  border: 1px solid #777;
  transition: opacity 1s ease-out;
  overflow: auto;

  border-top: none;
  border-top-width: 0;
  list-style: none;
  max-height: 130px;
}

.suggestions::-webkit-scrollbar {
  width: 3px;
  border-radius: 20px;
}

.suggestions::-webkit-scrollbar-thumb {
  background-color: #202020;
}

.suggestions li {
  padding: 3px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #202020;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #ccc;
  color: #f3f3f3;
  cursor: pointer;
  font-weight: 600;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}


/* New station - step 2 */

.step2 h3 {
  width: 100%;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  padding: 20px 0px 10px 0px;
}

.chargers-list {
  background-color: #202020;
  width: 1000px;
  min-height: calc(100vh - 500px);
  border: 4px solid #999;
  border-radius: 20px;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  flex-wrap: wrap;
}

.my-modal-dialog {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px !important;
  opacity: 1 !important;
}

.my-modal-content {
  width: 900px !important;
  display: block;
  margin: 0 auto;
}

.modal-h1 {
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: 500;
  margin: 10px 0px;
  font-family: 'Raleway', sans-serif;
  line-height: 20px;

}

.modal-header {
  border: 0 !important;
}

.modal-header button {
  display: block;
  width: 20px;
  height: 20px;
  border: none;
  background-image: url('../public/icons/x-icon-light-gray.png');
  background-size: 100%;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  font-size: 25px;
  line-height: 30px;
}

.modal-header button:hover {
  background-image: url('../public/icons/x-icon-gray.png');
  cursor: pointer;
}

.modal-footer {
  border: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.submit-charger {
  line-height: 40px;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  color: #f3f3f3;
  background-color: #202020;
  border-radius: 10px;
  border: 1px solid #999;
  padding: 0px 15px;
  margin-right: 10px;
  transition: 0.3s;
}

.submit-charger:hover {
  cursor: pointer;
  background-color: #303030;
  border: 1px solid #999;
}

.delete-charger {
  line-height: 40px;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  color: #f3f3f3;
  background-color: #d14d4d;
  border-radius: 10px;
  border: 1px solid #d14d4d;
  padding: 0px 15px;
  margin-right: 10px;
  transition: 0.3s;
}

.delete-charger:hover {
  cursor: pointer;
  background-color: #d36262;
  border: 1px solid #d36262;
}

.cancel-charger {
  line-height: 40px;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  color: #404040;
  background-color: #e3e3e3;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0px 15px;
  margin-right: 30px;
  transition: 0.5s;
}

.cancel-charger:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

.charger {
  text-decoration: none;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  background-color: #d5d5d5;
  border: 2px solid #bbb;
  border-radius: 5px;
  width: 236px;
  height: 85px;
  color: #555;
  transition: 0.15s;
}

.charger:hover {
  color: #777;
  cursor: pointer;
}

.charger h2 {
  font-size: 65px;
  font-weight: 400;
  line-height: 50px;
}

.charger h5 {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.charger h4 {
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 10px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  text-align: left;
}

.charger p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.charger p span {
  font-weight: 400;
  font-style: italic;
}

.charger-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #202020;
}

.label-input {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label-input input {
  width: 285px;
  line-height: 25px;
  font-size: 15px;
  margin: 10px 0px;
}

.label-input h5 {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  
}

.input-with-help {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.input-with-help p span {
  font-size: 12px;
  font-style: italic;
  color: #505050;
}

.error-selected {
  border: 2px dotted #ff0000;
}

.error-p-input {
  width: 100%;
  text-align: center;
  color: #ff0000 !important;
  width: 700px;
  font-size: 13px;
  line-height: 20px;
  padding-top: 10px;
  font-weight: 500;
}

.steps-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.back-step {
  margin: 20px 0px 15px 0px;
  width: 60px;
  height: 50px;
  outline: none;
  border: 1px solid #000;
  border-radius: 2px;
  background-color: #202020;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 50px;
  transition: 0.3s;
}

.back-step img {
  width: 15px;
  height: 20px;
}

.back-step:hover {
  background-color: #303030;
  border: 1px solid #404040;
  color: #fff;
  cursor: pointer;
}

.groups-list {
  background-color: #202020;
  width: 1000px;
  min-height: calc(100vh - 500px);
  border: 4px solid #999;
  border-radius: 20px;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: start;
  flex-wrap: wrap;
}

.group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-center;
  width: 100%;
  margin: 0px 0px 20px 0px;
  outline: none;
  border: 1px solid #bbb;
  padding: 10px;
  background-color: #d5d5d5;
  border-radius: 5px;
  color: #555;
  transition: 0.15s;
}

.group h2 {
  font-size: 20px;
  font-weight: 400;
}

.group p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
}

.group p span {
  font-weight: 400;
  font-style: italic;
}

.group button {
  width: 200px;
  height: 50px;
  background-color: #404040;
  border: 1px solid #aaa;
  outline: none;
  color: #f3f3f3;
  border-radius: 30px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 15px;
  font-family: 'Raleway', sans-serif;
  transition: 0.3s;
}

.group button:hover {
  background-color: #777;
  border: 1px solid #ccc;
  cursor: pointer;
}

.inline-div {
  margin: 0;
}

.group-info {
  max-width: 700px;
}

.group-info div h3 {
  font-size: 15px;
  line-height: 20px;
  padding: 10px 0px 0px 0px;
  margin: 0;
  font-weight: 600;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  text-align: left;

}

.RSPBprogressBar {
  width: 400px;
  height: 70px;
  margin: 20px 0px;
}

.step-done {
  font-size: 16px;
  line-height: 13px;
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif;
  background-color: #202020;
  border-radius: 40px;
  border: 2px solid #202020;
  color: #f3f3f3;
  padding: 8px;
  width: 13px;
  height: 13px;
}

.step-not-done {
  font-size: 16px;
  line-height: 13px;
  font-weight: 400;
  font-family: 'Noto Sans', sans-serif;
  background-color: #f3f3f3;
  border-radius: 40px;
  border: 2px solid #202020;
  color: #202020;
  padding: 8px;
  width: 13px;
  height: 13px;
}

.step-selected {
  font-size: 16px;
  line-height: 13px;
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif;
  background-color: #f3f3f3;
  border-radius: 40px;
  border: 1px solid #202020;
  color: #202020;
  padding: 8px;
  width: 13px;
  height: 13px;

  animation-duration: 9s;
  animation-name: stepSelected;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}


@keyframes stepSelected {
  0% {
    background-color: #f3f3f3;
    color: #202020;
  }

  50% {
    background-color: #202020;
    color: #f3f3f3;
  }

  100% {
    background-color: #f3f3f3;
    color: #202020;
  }
}

.error-next-step {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 20px 0px 0px;
}


.demand-centered {
  width: 100%;
}

.demand-centered img {
  width: 60%;
}

.function-image {
  padding: 0px 10px;
  border: 2px dotted #202020;
  border-radius: 10px;
  display: block;
  margin: 20px auto 20px auto;
}

.grid-price {
  width: 200px;
  height: 50px;
  background-color: #202020;
  border: 1px solid #aaa;
  outline: none;
  color: #f3f3f3;
  border-radius: 30px;
  margin: 0px 20px 0px 0px;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  transition: 0.3s;
}

.grid-price:hover {
  background-color: #404040;
  border: 1px solid #ccc;
  cursor: pointer;
}

.grid-price div {
  margin-left: 20px;
  height: 20px;
  width: 20px;
  background-color: #f3f3f3;
  border: 2px dotted #555;
  border-radius: 10px;
}

.grid-price:hover div {
  background-color: #ddd;
}

.grid-price div img {
  height: 15px;
  width: 15px;
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0px;
}

.plus-icon {
  margin: 0px 30px 0px 10px;
  font-size: 30px;
  font-weight: 500;
}

.all-expenses-calc {
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  font-weight: 400;
  font-size: 14px;
  color: #777;
  font-family: 'Raleway', sans-serif;
}

.all-expenses-calc span {
  font-weight: 500;
}

.info-method {
  padding: 10px;
}

.info-method p {
  text-align: center;
  margin: 10px 10px 0px 10px;
  padding: 10px 50px 10px 50px;
  color: #888;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  border: 1px dotted #202020;
  border-radius: 3px;
}

.info-method p span {
  font-weight: 500;
}

.fixed-price {
  width: 100%;
}

.fixed-price img {
  width: 130px;
}

.fixed-profit {
  width: 100%;
}

.fixed-profit img {
  width: 280px;
}

.competitor-centered {
  width: 100%;
}

.competitor-centered img {
  width: 55%;
  padding: 5px 0px;
}

.competitors-selected {
  display: block;
  margin: 0px 10px 0px 10px;
  width: 70%;
  background-color: #bbb;
  border-radius: 10px;
  border: 2px dotted #aaa;
}

.competitors-selected h3 {
  padding: 20px 0px 10px 0px;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  color: #202020;
  border-bottom: 2px dotted #f3f3f3;
}

.competitors-selected h4 {
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  padding: 10px 0px 10px 0px;
  color: #404040;
}

.competitors-selected ul {
  list-style: none;
  max-height: 300px;
  padding-top: 5px;
  overflow: scroll;
  overflow-x: hidden;
}

/* width */
.competitors-selected ul::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.competitors-selected ul::-webkit-scrollbar-track {
  background: #bbb; 
  margin: 4px 0px 6px 0px;
}
 
/* Handle */
.competitors-selected ul::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.competitors-selected ul::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.competitors-selected ul li {
  margin: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 3px;
}

.competitors-selected ul li h5 {
  font-weight: 500;
  font-size: 15px;
}

.competitors-selected ul li h6 {
  font-weight: 400;
  font-size: 13px;
}

.pricing-method-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.pricing-method-info h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
}

.pricing-method-info h4 span {
  font-weight: 400;
  font-style: italic;
}

.pricing-method-info ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 0;
}

.pricing-method-info ul li {
  list-style: none;
}

.pricing-method-info ul li p {
  padding: 10px 10px 0px 0px;
  margin: 0;
}

.methods-info {
  width: 100%;
  padding-top: 10px;
  opacity: 0.8;
}

.methods-info a {
  background-color: #f8f8f8;
  text-align: center;
  color: #202020;
  padding: 10px 10px;
  border-radius: 4px;;
  border: 1px dotted #202020;
  margin: 0px 0px 20px 0px;

}


.station-final-overview {
  background-color: #202020;
  width: 1000px;
  min-height: calc(100vh - 500px);
  border: 4px solid #999;
  border-radius: 20px;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  align-content: start;
  flex-wrap: wrap;
}

.step-4-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #c3c3c3;
  padding: 10px 10px;
  border: 2px solid #aaa;
  border-radius: 5px;
  margin: 0px 0px 20px 0px;
}

.step-4-check h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  color: #202020;
  line-height: 30px;
  padding: 10px 0px 20px 0px;
}

.step-4-check button {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #f3f3f3;
  line-height: 25px;
  background-color: #202020;
  border: 2px solid #aaa;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 30px 0px 10px 0px;
  transition: 0.2s;
}

.step-4-check button:hover {
  background-color: #404040;
  cursor: pointer;
}

.step-4-check-inside {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 800px;
}

.step-4-check-inside2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 800px;
}

.step-4-check-inside3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 800px;
}

.step-4-station-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.step-4-station-info h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #202020;
  line-height: 30px;
}

.step-4-station-info h3 span {
  font-weight: 600;
}

.charger-overview {
  text-decoration: none;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  background-color: #d5d5d5;
  border: 2px solid #bbb;
  border-radius: 5px;
  width: 232px;
  color: #303030;
  transition: 0.15s;
}

.charger-overview h4 {
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 10px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  text-align: left;
}

.charger-overview p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.charger-overview p span {
  font-weight: 400;
  font-style: italic;
}

.group-station-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-center;
  width: 80%;
  margin: 0px 0px 20px 0px;
  outline: none;
  border: 1px solid #bbb;
  padding: 10px;
  background-color: #d5d5d5;
  border-radius: 5px;
  color: #555;
  transition: 0.15s;
}

.group-station-check h2 {
  font-size: 20px;
  font-weight: 400;
}

.group-station-check p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;
}

.group-station-check p span {
  font-weight: 400;
  font-style: italic;
}

.end-step {
  margin: 20px 290px 15px 0px;
  width: 400px;
  height: 50px;
  outline: none;
  border: 1px solid #000;
  border-radius: 2px;
  background-color: #202020;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 50px;
  transition: 0.3s;
}

.end-step:hover {
  background-color: #303030;
  border: 1px solid #404040;
  color: #fff;
  cursor: pointer;
}

.station-overview {
  padding-bottom: 50px;
}

.station-nav-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.station-nav-ul li {
  list-style: none;
  margin: 0 3px;
}
.item-active {
  color: #fff;
  font-weight: 500;
  text-shadow: 0px 1px 1px #fff;
}

.station-nav-ul li a {
  padding: 0px 10px;
  color: #f3f3f3;
  text-decoration: none;
  line-height: 20px;
}

.station-nav-ul li a:hover {
  text-shadow: 0px 1px 1px #f3f3f3;
  
}

.overview-station-div1 {
  background-color: #e3e3e3;
  width: 440px;
  margin: 30px 0px;
  border-radius: 10px;
  padding: 10px 0px 0px 0px;
}

.overview-station-div1 p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 15px;
  text-align: center;
  font-style: italic;
}

.text-icon {
  margin: 5px 0px 10px 0px;
}

.text-icon h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.text-icon img {
  margin: 0px 0px 0px 5px;
  width: 30px;
  height: 27px;
}

.percentage-availability {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border: 1px solid #202020;
  background-color: #f3f3f3;
  margin-bottom: 3px;
}

.overview-station-ul1 {
  list-style: none;
  padding: 0px 0px 10px 0px;
}

.overview-station-ul1 li {
  background-color: #f3f3f3;
  margin: 0px 0px 20px 0px;
  width: 350px;
  padding: 8px 5px;
  border-radius: 2px;
}

.overview-station-ul1 li h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 25px;
  font-size: 17px;
}

.overview-station-ul1 li h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 5px;
}

.overview-station-ul1 li h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 25px;
  font-size: 17px;
  margin-top: 5px;
}

.overview-station-ul2 {
  list-style: none;
  padding: 0px 0px 30px 0px;
}

.overview-station-ul2 li {
  background-color: #f3f3f3;
  margin: 10px 0px 0px 0px;
  width: 350px;
  padding: 8px 5px;
  border-radius: 2px;
}

.overview-station-ul2 li a {
  text-decoration: none;
  color: #202020;
  display: block;
  width: 100%;
}

.overview-station-ul2 li a:hover {
  background-color: #eee;
}

.overview-station-ul2 li h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
}

.overview-station-ul2 li h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 22px;
  font-size: 15px;
}

.overview-station-ul2 li h5 span {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 22px;
  font-size: 15px;
  font-style: italic;
}

.loading {
  display: block;
  margin: 20px auto;
  opacity: 0.7;
  width: 100%;
}

.station-prices {
  width: 100%;
  padding-bottom: 50px;
}
.station-prices h1 {
  line-height: 50px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
}

.station-prices-ul1 {
  list-style: none;
  min-width: 400px;
  background-color: #e3e3e3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px 10px 10px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  margin-bottom: 10px;
}

.station-prices-ul1 li {
  margin: 0px 10px 0px 0px;
  background-color: #f3f3f3;
  padding: 10px;
  border: 2px solid #606060;
  border-radius: 3px;
}

.station-prices-ul1 li h3 {
  line-height: 25px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #202020;
  text-align: center;
}

.station-prices-ul1 li h4 {
  line-height: 25px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
  text-align: center;
}

.station-prices-current-price {
  height: 70px;
  background-color: #f3f3f3;
  border: 2px dashed #404040;
  padding: 0px 30px;
  margin: 20px 0px 30px 0px;
}

.station-prices-current-price h2 {
  line-height: 70px;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
  text-align: center;
}

.station-prices-current-price h2 span {
  font-weight: 600;
  color: #ff0000;
  margin-left: 5px;
}

.station-prices-div1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.station-prices-div1 h3 {
  width: 100%;
  line-height: 40px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
  margin-left: 20px;
}

.station-prices-div2 {
  width: 100%;
}

.station-prices-pricing-method {
  width: 65%;
  background-color: #e3e3e3;
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: 20px;
}

.station-prices-pricing-method h2, .station-prices-pricing-method h4 {
  width: 100%;
  line-height: 40px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #202020;
}

.method_images img {
  max-width: 600px;
  max-height: 80px;
  display: block;
  margin: 0 auto;;
}

.station-prices-pricing-method p {
  width: 100%;
  line-height: 30px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #202020;
  margin-left: 20px;
}

.station-prices-pricing-method p span {
  font-style: italic;
}

.station-prices-pricing-method button {
  outline: none;
  border: none;
  background-color: #e3e3e3;
  display: block;
  margin: 0 auto;
  line-height: 20px;
  font-style: italic;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #7272CC;
  border-radius: 5px;
  padding: 3px;
  transition: 0.3;
}

.station-prices-pricing-method button:hover {
  cursor: pointer;
  color: #8a8adb;
}

.station-prices-right {
  width: 25%;
}

.station-prices-chargers {
  width: 100%;
  background-color: #e3e3e3;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  margin-bottom: 20px;
}

.station-prices-chargers h5 {
  line-height: 30px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #202020;
}

.station-prices-chargers a {
  text-decoration: none;
  background-color: #e3e3e3;
  display: block;
  margin: 0 auto;
  line-height: 18px;
  font-style: italic;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #7272CC;
  border-radius: 5px;
  padding: 3px;
  transition: 0.3;
  margin-bottom: 12px;
}

.station-prices-chargers a:hover {
  cursor: pointer;
  color: #8a8adb;
}

.station-prices-chargers ul {
  list-style: none;
}

.station-prices-chargers ul li {
  line-height: 30px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #202020;
}

.station-prices-grid {
  width: 100%;
  background-color: #e3e3e3;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

.station-prices-grid h5 {
  line-height: 30px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #202020;
}

.station-prices-grid h3 {
  line-height: 30px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #ff0000;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.station-prices-grid h4 {
  line-height: 30px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #202020;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.station-prices-selected {
  opacity: 1;
  transition: 0.3s;
}

.station-prices-selected:hover {
  cursor: pointer;
  background-color: #eee;
}

.station-prices-not-selected {
  opacity: 0.4;
  transition: 0.3s;
}

.station-prices-not-selected:hover {
  cursor: pointer;
  background-color: #e3e3e3;
}


.station-chargers {
  width: 100%;
  padding-bottom: 100px;
}

.station-chargers h1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 80px;
  font-family: 'Raleway', sans-serif;
}

.station-chargers-div1 {
  width: 95%;
  margin: 0px 0px 50px 0px;
}

.station-chargers-div1 h2 {
  line-height: 35px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #404040;
}

.station-chargers-div2 {
  background-color: #d5d5d5;
  color: #303030;
  font-size: 35px;
  line-height: 50px;
  padding: 5px 20px;
  border: none;
  outline: none;
  border-radius: 8px;
}

.station-chargers-div2:hover {
  background-color: #ddd;
  color: #404040;
  cursor: pointer;
}

.station-chargers-div2 h2 {
  font-size: 15px;
  line-height: 25px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
}

.station-chargers-div2 p {
  font-size: 60px;
  line-height: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
}

.station-chargers-ul1 {
  background-color: #202020;
  list-style: none;
  padding: 20px 20px 10px 20px;
  width: calc(100% - 40px);
  border-radius: 5px;
  border: 3px solid #909090;
}

.station-chargers-ul1 li {
  width: 98%;
  padding: 8px;
  margin: 0px 0px 10px 0px;
  border-radius: 8px;
  text-decoration: none;
  background-color: #d5d5d5;
  border: 2px solid #bbb;
}

.station-chargers-ul1 li h2 {
  font-weight: 500;
  color: #202020;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}

.add-charger {
  font-weight: 500;
  color: #202020;
  font-size: 35px;
  line-height: 50px;
  padding: 0px !important;
  margin: 0px !important;
  font-family: 'Raleway', sans-serif;
  width: 90px !important;
  border: none !important;
}

.add-charger button {
  width: 90px;
  background-color: transparent;
  font-weight: 500;
  color: #202020;
  font-size: 35px;
  line-height: 50px;
  font-weight: 500;
  color: #202020;
  font-size: 35px;
  line-height: 50px;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 8px;

}

.add-charger button:hover {
  cursor: pointer;
  background-color: #f3f3f3;
  border: none;
  outline: none;
}

.station-chargers-ul1 li p {
  font-weight: 400;
  color: #202020;
  font-size: 15px;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
}

.station-chargers-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.station-chargers-buttons button {
  border: none;
  background-color: transparent;
}

.station-chargers-buttons button:hover {
  cursor: pointer;
  text-shadow: 0px 1px 1px #f3f3f3;
}

.station-chargers-buttons-delete {
  color: #E5467E;
}

.blue-button-transparent {
  color: #7272CC;
  margin-bottom: 2px;
}

.blue-button-transparent:hover {
  cursor: pointer;
  text-shadow: 0px 1px 1px #c3c3c3;
}

/* station reservations page */
.station-reservations {
  width: 100%;
  padding-bottom: 50px;
}
.station-reservations h1 {
  line-height: 70px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
}
.station-reservations h3 {
  line-height: 40px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #202020;
}

.label-input-reservations {
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label-input-reservations input {
  width: 200px;
  line-height: 25px;
  font-size: 15px;
  margin: 10px 0px;
}

.label-input-reservations h5 {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  width: 50%;
}

.reservation-button {
  line-height: 40px;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  color: #f3f3f3;
  background-color: #202020;
  border-radius: 10px;
  border: 1px solid #999;
  padding: 0px 15px;
  margin: 10px 10px 20px 0px;
  transition: 0.3s;
}

.reservation-button:hover {
  cursor: pointer;
  background-color: #303030;
  border: 1px solid #999;
}

.create-reservation-button {
  align-self: flex-end;
  font-weight: 500;
  margin: 0px 10px 20px 0px;
}


.label-input-reservations1 {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label-input-reservations1 input {
  width: 200px;
  line-height: 25px;
  font-size: 15px;
  margin: 10px 0px;
}

.label-input-reservations1 h5 {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  width: 50%;
}

.station-reservations-ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #202020;
  list-style: none;
  padding: 20px 20px 10px 20px;
  width: 100%;
  border-radius: 5px;
  border: 3px solid #909090;
  opacity: 1;
  transition: 3s;
}

.station-reservations-ul li {
  width: 98%;
  padding: 8px;
  margin: 0px 0px 10px 0px;
  border-radius: 8px;
  text-decoration: none;
  background-color: #d5d5d5;
  border: 2px solid #bbb;
}

.station-reservations-ul li h3 {
  font-weight: 400;
  color: #202020;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}


.vehicle-state h1 {
  line-height: 70px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
}

.vehicle-state-div {
  background-color: #f1f1f1;
  border: 4px solid #d7d7d7;
  border-radius: 10px;
  width: 80%;
  padding: 20px 0px;
}

.vehicle-state-div2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 3px 20px;
}

.vehicle-state-div2 h3 {
  line-height: 30px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
}

.vehicle-state-div2 h4 {
  display: block;
  width: 400px;
  line-height: 30px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #202020;
}

.loading-big {
  position: fixed;
  top: calc(50% - 80px);
  left: 50%;
}

.loading-center {
  position: absolute;
  left: 50%;
  top: 50%;
}

.edit-pricing-group {
  background-color: transparent;
  border: none;
  color: #7272CC;
}

.edit-pricing-group:hover {
  cursor: pointer;
  text-shadow: 0px 1px 1px #c3c3c3;
}

.green-button-transparent {
  background-color: transparent;
  border: none;
  color: #25903a;
}

.green-button-transparent:hover {
  cursor: pointer;
  text-shadow: 0px 1px 1px #c3c3c3;
}

.vehicle-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #d3d3d3;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
  margin-bottom: 20px;
  min-width: 400px;
}

.vehicle-info h2 {
  line-height: 40px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
  text-align: center;
  width: 100%;
}

.vehicle-info h4 {
  line-height: 24px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #202020;
}

.vehicle-info h3 {
  line-height: 24px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #404040;
}

.black-button-transparent {
  background-color: transparent;
  border: none;
  color: #202020;
}

.black-button-transparent:hover {
  cursor: pointer;
  text-shadow: 0px 1px 1px #404040;
}

.span-state-black {
  color: #202020;
  padding-left: 5px;
  font-weight: 500;
}

.span-state-blue {
  color: #7272CC;
  padding-left: 5px;
  font-weight: 500;
}

.span-state-green {
  color: #5e8e60;
  padding-left: 10px;
  font-weight: 500;
}

.span-state-red {
  color: #fa2626;
  padding-left: 10px;
  font-weight: 500;
}

.reservation-view h2 {
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Raleway', sans-serif;
  line-height: 35px;
  padding-top: 10px;
}

.reservation-view h3 {
  font-weight: 400;
  line-height: 26px;
  font-size: 17px;
  font-family: 'Raleway', sans-serif;
}

.reservation-view h4 {
  line-height: 26px;
  font-weight: 400;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
}

.reservation-view p {
  line-height: 22px;
  font-weight: 400;
  font-size: 15px;
  padding: 3px 5px;
  width: 500px;
  text-align: center;
  font-style: italic;
  font-family: 'Raleway', sans-serif;
}

.reservation-view-titles {
  width: 60%;
}

.reservation-view-numbers {
  width: 40%;
}

.my-checkbox {
  width: 25px;
  height: 25px;
  border: 1px solid #aaa;
  border-radius: 2px;
  background-color: #f3f3f3;
  margin-right: 5px;
}

.my-checkbox-checked {
  width: 25px;
  background-image: url('../public/icons/check2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}

.my-checkbox-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px 10px;
  transition: 0.5s;
  user-select: none;
}

.my-checkbox-all:hover {
  background-color: #d1d1d1;
  cursor: pointer;
}

.my-checkbox-all p {
  color: #202020;
}

.parking {
  width: 100%;
}

.parking h1 {
  padding: 30px 0px 20px 0px;
  line-height: 50px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #202020;
}

.parking h3 {
  padding: 20px 0px 5px 0px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #202020;
}

.button-parking-cost {
  line-height: 50px;
  font-size: 20px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  margin-top: 30px;
  color: #f3f3f3;
  background-color: #202020;
  border-radius: 3px;
  border: 1px solid #777;
  padding: 0px 50px;
  transition: 0.2s;
}

.button-parking-cost:hover {
  cursor: pointer;
  background-color: #303030;
  border: 1px solid #aaa;
}

.light-red-background {
  background-color: #f5caca !important;
}

.gridprices-chart {
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: #e3e3e3;
  padding: 20px 10px 20px 10px;
  border-radius: 3px;
  border: 1px solid #aaa;
}

.gridprices-chart h3 {
  text-align: center;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #202020;
}

.gridprices-chart p {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #404040;
  padding-top: 30px;
}

.gridprices-chart-chart {
  margin: 10px 5px 10px 5px;
  background-color: #e9e9e9;
  padding: 10px 10px 10px 5px;
  border-radius: 2px;
}

.custom-tooltip1 {
  background-color: #f3f3f3;
  padding: 3px 5px;
  opacity: 0.7;
  border: 1px solid #aaa;
}

.custom-tooltip1 p {
  font-size: 14px;
  line-height: 20px;
  padding: 0px;
  font-weight: 400;
  color: #404040;
  font-family: 'Poppins', sans-serif;
  text-align: left;
}

.custom-tooltip1 p span {
  font-weight: 500;
}

.comment-h4 {
  padding: 20px 10px 0px 10px;
  font-size: 14px;
  line-height: 10px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.comment-h4 span {
  font-weight: 500;
  font-style: normal;
}

.statistics {
  width: 100%;
  padding-bottom: 80px;
}

.statistics h1 {
  font-size: 25px;
  line-height: 90px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  font-family: 'Raleway', sans-serif;
}

.statistics-info {
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  max-width: 500px;
  font-family: 'Raleway', sans-serif;
  padding: 0px 0px 30px 0px;
}

.chart h3 {
  text-align: center;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #202020;
}

.reservations-chart p {
  padding-top: 10px;
}

.reservations-chart p span {
  font-weight: 600;
  padding-left: 3px;
}

.custom-tooltip2 {
  background-color: #f3f3f3;
  padding: 3px 5px;
  opacity: 0.7;
  border: 1px solid #aaa;
  border: 1px solid #aaa;
}

.custom-tooltip2 p {
  text-align: left;
  line-height: 23px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 1px 2px;
}

.custom-tooltip2 p span {
  font-weight: 600;
  padding-left: 5px;
}

.pie-charts-external {
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: #e3e3e3;
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 20px 10px 20px 10px;
}

.pie-charts-external h2 {
  text-align: center;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #202020;
}

.pie-charts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 930px;
  min-width: 600px;
  align-items: center;
  justify-content: center;
  background-color: #e3e3e3;
}

.pie-charts div {
  background-color: #e9e9e9;
  margin: 0px 3px 10px 3px;
  border-radius: 10px;
  padding: 3px;
}

.pie-charts div h3 {
  text-align: center;
  padding-top: 10px;
  line-height: 30px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.recharts-legend-item-text {
  line-height: 25px;
}

.statistics-stations {
  background-color: #303030;
  padding: 20px 10px;
  max-width: 900px;
  margin-bottom: 10px;
}

.statistics-stations div {
  flex-wrap: wrap;
}

.statistics-stations h2 {
  color: #f3f3f3;
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  padding-bottom: 10px;
}

.pie-chart-p {
  line-height: 25px;
  font-size: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.pie-chart-p span{
  font-weight: 500;
  padding-left: 7px;
}

.healthy-charger {
  color: #61c45e !important;
  font-weight: 600 !important;
}

.not-healthy-charger {
  color: #d14d4d !important;
  font-weight: 600 !important;
}

.not-healthy-chargers h2 {
  font-size: 17px;

}

.no-requests {
  font-size: 18px;
  font-weight: 400;
  color: #aaa;
  font-style: italic;
  text-align: center;
  padding-bottom: 10px;
}

.notif-settings {
  color: #d36262 !important;
  font-style: italic;
}

.pricing-methods-landing {
  height: calc(60vh);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-image: url('../public/img/electric-vehicle-card.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.pricing-methods-landing h1 {
  font-size: 30px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  color: #f3f3f3;
  padding-bottom: 100px;
}

.pricing-methods-page h2, .pricing-methods-page p {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.pricing-methods-section1 {
  background-color: #202020;
}

.pricing-methods-section1 p {
  font-family: 'Raleway', sans-serif;
  font-size: 17px;
  line-height: 30px;
  font-weight: 500;
  padding: 0px 150px 50px 150px;
  color: #f3f3f3;
}

.pricing-methods-section1 h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 28px;
  font-weight: 500;
  padding: 50px 0px 50px 0px;
  color: #f3f3f3;
}

.pricing-methods-section2 p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 500;
  color: #202020;
  text-align: center;
  display: block;
  width: 600px;
}

.pricing-methods-section2 h2 {
  font-size: 28px;
  font-weight: 500;
  padding: 50px 0px 50px 0px;
  color: #202020;
}

.pricing-methods-section2 h4 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #202020;
  padding: 5px 30px;
  font-style: italic;
  display: block;
  width: 600px;
  text-align: center;
  border: 1px solid #aaa;
  border-radius: 5px;
  background-color: #ddd;
  margin-bottom: 40px;
}

.pricing-methods-section2 img {
  height: 60px;
  pointer-events: none;
  user-select: none;
  box-shadow: 3px 3px 3px 3px #aaa;
  border: 1px solid #707070;
}

.pricing-methods-section2 hr {
  border-top: 2px solid #202020;
  width: 400px;
}

.blue-info {
  color: #39397a !important;
  font-style: italic;
}

.owner-div {
  background-color: #dddddd;
  width: 420px;
  padding: 30px 50px;
  border-radius: 12px;
  margin-bottom: 30px;
}

.owner-div h2 {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  color: #202020;
  padding-bottom: 20px;
  font-family: 'Raleway', sans-serif;
}

.owner-div div {
  width: 100%;
}

.owner-div h3 {
  font-size: 17px;
  text-align: left;
  font-weight: 400;
  color: #202020;
}

.owner-button {
  background-color: #202020;
  padding: 5px 20px;
  border: none;
  outline: none;
  border-radius: 2px;
  margin-top: 20px;
  transition: 0.3s;
  text-decoration: none;
}

.owner-button:hover {
  background-color: #505050;
  cursor: pointer;
}

.owner-button h5 {
  font-size: 18px;
  line-height: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
  color: #f3f3f3;
  width: 150px;
}

.owner-button h4 {
  font-size: 40px;
  line-height: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: center;
  color: #f3f3f3;
  width: 60px;
}

.owner-button h6 {
  font-size: 20px;
  line-height: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
  color: #f3f3f3;
}

.owner-button p {
  font-size: 60px;
  line-height: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
}

.width-800 {
  width: 800px !important;
}

.vehicles-ul {
  list-style: none;
  width: 100%;
}

.vehicles-ul li {
  width: 98%;
  padding: 8px;
  margin: 0px 0px 10px 0px;
  border-radius: 8px;
  text-decoration: none;
  background-color: #eeeeee;
}

.vehicles-ul li h2 {
  font-weight: 500;
  color: #202020;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
}

.vehicles-ul li p {
  font-weight: 400;
  color: #202020;
  font-size: 15px;
  line-height: 23px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
}

.owner-dashboard {
  padding: 0px 0px 100px 0px;
}

.owner-reservation {
  padding-bottom: 200px;
  width: 700px;
}

.disclosure-p {
  margin: 20px 0px 0px 0px;
  font-weight: 400;
  color: #505050;
  font-size: 16px;
  line-height: 23px;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  font-style: italic;
}

.change-info-p {
  font-weight: 400;
  color: #505050;
  font-size: 16px;
  line-height: 23px;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.change-info-p a {
  color: #202020;
}

.selected-station {
  background-color: #dddddd;
  padding: 10px 40px;
  margin: 10px 0px 30px 0px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.selected-station h4 {
  font-weight: 400;
  font-style: normal;
  color: #202020;
  font-size: 19px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.selected-station h5 {
  font-weight: 500;
  font-style: normal;
  color: #202020;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.selected-station h5 span {
  font-weight: 400;
  font-style: italic;
  color: #202020;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.width-600 {
  width: 900px !important;
}

.station-chargers-ul1-style2 {
  background-color: #dddddd;
  list-style: none;
  padding: 20px 40px 10px 40px;
  width: 800px;
  border-radius: 5px;
  border: 3px solid #909090;
}

.station-chargers-ul1-style2 li {
  width: 98%;
  padding: 8px;
  margin: 0px 0px 10px 0px;
  border-radius: 8px;
  text-decoration: none;
  background-color: #eeeeee;
  border: 2px solid #bbb;
}

.station-chargers-ul1-style2 li h2 {
  font-weight: 500;
  color: #202020;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}


.few-words {
  padding: 50px 10px;
}

.few-words h2 {
  text-align: center;
  font-weight: 600;
  color: #202020;
  font-size: 25px;
  line-height: 25px;
  padding-bottom: 30px;
  font-family: 'Raleway', sans-serif;
}

.few-words p {
  text-align: center;
  font-weight: 400;
  line-height: 28px;
  color: #202020;
  font-size: 17px;
  font-family: 'Raleway', sans-serif;
}

.big-hr {
  border-top: 2px solid #acacac;
  width: 350px;
}

.cards {
  padding: 70px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cards ul {
  list-style: none;
  width: 25%;
}

.cards ul li {
  background-color: transparent;
  width: 270px;
  margin: 12px 0px;
  padding: 5px 7px;
  transition: 0.9s;
  border-radius: 5px;
  box-shadow: 3px 3px 10px #d4d4d4;
  border: 1px solid #dedddd;
  color: #202020;
}

.cards ul li:hover {
  background-color: #e3e3e3;
  cursor: pointer;
}

.active-card {
  background-color: #e1e1e1!important;
  border: 1px solid #b9b9b9!important;
}

.cards ul li h3 {
  font-weight: 500;
  color: #202020;
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 5px;
  font-family: 'Raleway', sans-serif;
}

.cards ul li h4 {
  font-weight: 400;
  color: #202020;
  font-size: 16px;
  line-height: 25px;
  font-family: 'Raleway', sans-serif;
}

.cards div {
  width: 70%;
}

.cards div img {
  width: 900px;
  box-shadow: 20px 20px 35px #727272;
}

.width-1300 {
  width: 1300px;
}

.learn-more {
  background-color: #202020;
  width: 450px;
  border-radius: 20px;
  padding: 40px 0px;
  margin: 20px 0px 40px 0px;
  box-shadow: 5px 5px 20px #a1a1a1;
}

.learn-more h4 {
  font-weight: 500;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 20px;
  font-family: 'Raleway', sans-serif;
}

.learn-more a {
  font-weight: 500;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 25px;
  font-family: 'Raleway', sans-serif;
}


.about-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 335px);
  width: 100%;
}

.about-words {
  width: 40%;
}

.about-words h1 {
  font-weight: 600;
  color: #202020;
  font-size: 30px;
  line-height: 25px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding-bottom: 40px;
}

.about-words p {
  font-weight: 400;
  color: #202020;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.about-words p a {
  color: #202020;
}

.about-words {
  width: 40%;
}

.about-image {
  width: 55%;
  min-height: calc(100vh - 335px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-image: url('../public/img/chargers-about-page-ready2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.contact-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 335px);
  width: 100%;
}

.contact-words {
  width: 40%;
}

.contact-image {
  width: 55%;
  min-height: calc(100vh - 335px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-image: url('../public/img/car-contact2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.contact-words h1 {
  font-weight: 600;
  color: #202020;
  font-size: 30px;
  line-height: 25px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding-bottom: 40px;
}

.contact-words p {
  font-weight: 400;
  color: #202020;
  font-size: 16px;
  font-style: italic;
  line-height: 25px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding-bottom: 30px;
}

.contact-words p a {
  color: #202020;
}

.contact-input1 {
  width: 50% !important;
  margin: 0px 5px !important;
}

.contact-input2 {
  width: 95% !important;
}

.contact-f {
  margin-top: 30px;
}

.green-p {
  color: #5e8e60!important;
  font-weight: 600!important;
}


/* phone view */

.charging-operator-phone {
  width: 100%;
  height: calc(100vh - 15px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.charging-operator-phone h1 {
  font-family: 'Noto Sans', sans-serif;
  text-align: center;
  font-weight: 900;
  font-size: 70px;
  line-height: 70px;
  color: #fff;
  font-weight: 400;
  text-shadow: 3px 3px 3px #303030;
}


.few-words-phone {
  padding: 50px 0px;
  width: 100%;
}

.few-words-phone h2 {
  text-align: center;
  font-weight: 600;
  color: #202020;
  font-size: 25px;
  line-height: 25px;
  padding: 0px 20px 30px 20px;
  font-family: 'Raleway', sans-serif;
  line-height: 35px;
}

.few-words-phone p {
  text-align: center;
  font-weight: 400;
  line-height: 28px;
  color: #202020;
  padding: 0px 20px 30px 20px;
  font-size: 17px;
  font-family: 'Raleway', sans-serif;
}

.cards-phone {
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cards-phone ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cards-phone ul li {
  background-color: transparent;
  width: 90%;
  margin: 0px;
  padding: 15px 0px;
  transition: 0.9s;
  border-radius: 5px;
  box-shadow: 3px 3px 10px #d4d4d4;
  border: 1px solid #dedddd;
  color: #202020;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards-phone ul li h3 {
  font-weight: 500;
  color: #202020;
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 5px;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.cards-phone ul li h4 {
  font-weight: 400;
  color: #202020;
  font-size: 16px;
  line-height: 25px;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.cards-phone img {
  width: 100%;
  padding: 0px 5px 20px 5px;
}

.logo-phone {
  height: 70px;
  padding-left: 30px;
}

.logo-phone a img {
  height: 50px;
  margin: 0px 0px 0px 0px;
}

.right-nav1-phone {
  height: 70px;
  display: flex;
  padding: 0px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.right-nav1-phone ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  margin: 0px;
  padding: 0px 30px 0px 0px;
}

.right-nav1-phone ul li {
  color: #fff;
  line-height: 70px;
  margin: 0;
  padding: 0px 10px;
}

.right-nav1-phone ul li a {
  text-decoration: none;
  line-height: 50px;
  display: block;
  color: #f3f3f3;
  font-size: 15px;
}

.right-nav1-phone ul li a:hover {
  color: #f3f3f3;
  text-shadow: 0px 1px 1px #f3f3f3;
  cursor: pointer;
}

.nav1-background-phone {
  background-color: #202020;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  margin: 0px;
  width: 100%;
}

.learn-more-phone {
  background-color: #202020;
  width: 90%;
  border-radius: 20px;
  margin: 20px 0px 40px 0px;
  padding: 20px 0px;
}

.learn-more-phone h4 {
  font-weight: 500;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 20px;
  font-family: 'Raleway', sans-serif;
}

.learn-more-phone a {
  font-weight: 500;
  color: #f3f3f3;
  font-size: 20px;
  line-height: 25px;
  font-family: 'Raleway', sans-serif;
}


.footer-phone {
  background-color: #202020;
  width: 100%;
  padding: 75px 0px 25px 0px;
  margin: 0;
}

.footer-phone p {
  font-weight: 400;
  color: #c3c3c3;
  font-style: italic;
  font-size: 14px;
  line-height: 25px;
  font-family: 'Raleway', sans-serif;
  padding-top: 50px;
}

.footer-phone-left {
  width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-phone-right {
  width: 40%;
}

.footer-phone-left ul, .footer-phone-right ul {
  list-style: none;
}

.footer-phone-left ul li {
  text-align: left;
  color: #f3f3f3;
}

.footer-phone-right ul li {
  text-align: right;
  color: #f3f3f3;
}

.footer-phone-left ul li a {
  text-align: left;
  color: #f3f3f3;
  text-decoration: none;
  line-height: 30px;
}
.footer-phone-right ul li a {
  text-align: right;
  color: #f3f3f3;
  text-decoration: none;
  line-height: 30px;
}

.footer-phone-center {
  width: 10%;
}

.footer-phone-center img {
  width: 100%;
}

.icon-phone {
  background-color: #e5e5e5;
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: 1s;
  height: 200px;
  width: 30%;
}

.icon-phone img {
  padding: 10px;
  height: 60px;
}

.icon-phone p {
  font-size: 13px;
  padding: 5px 10px 10px 10px;
  text-align: center;
  color: #444;
}


.about-page-phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 335px);
  width: 100%;
}

.about-words-phone {
  width: 90%;
}

.about-words-phone h1 {
  font-weight: 600;
  color: #202020;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding: 40px 10px;
}

.about-words-phone p {
  font-weight: 400;
  color: #202020;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding-bottom: 30px;
}

.about-words-phone p a {
  color: #202020;
}

.about-image-phone {
  width: 100%;
  min-height: calc(50vh);
  display: block;
  background-image: url('../public/img/chargers-about-page-ready2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.contact-page-phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 335px);
  width: 100%;
}

.contact-words-phone {
  width: 90%;
}

.contact-image-phone {
  width: 100%;
  min-height: calc(100vh - 335px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-image: url('../public/img/car-contact2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.contact-words-phone h1 {
  font-weight: 600;
  color: #202020;
  font-size: 30px;
  line-height: 25px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding: 40px 5px 20px 5px;
}

.contact-words-phone p {
  font-weight: 400;
  color: #202020;
  font-size: 16px;
  font-style: italic;
  line-height: 25px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding-bottom: 5px;
}

.contact-words-phone p a {
  color: #202020;
}

.contact-input1-phone {
  width: 50% !important;
  margin: 0px 5px !important;
}

.contact-input2-phone {
  width: 95% !important;
}

.contact-f-phone {
  margin-top: 30px;
  padding-bottom: 30px;
}
